import React, { useEffect, Fragment } from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Box, Grid, Typography } from "@material-ui/core"

import Picto from "src/images/picto-amaury-beaufort.svg"

import SEO from "src/components/seo"

const Home = () => {
  useEffect(() => {
    console.log("=== Home ===")
  })

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/barrels.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <SEO />

      <Grid container justify="center" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="barrels"
            title="barrels"
          />
        </Grid>
        <Grid item xs={12}>
          <Box letterSpacing={4}>
            <Typography align="center">
              Cuvée 2018
              <br />
              bientôt disponible
              <br />
              sous allocation
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <img src={Picto} alt="Picto" title="Picto" />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Home
